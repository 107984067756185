import { Grid } from '@material-ui/core';
import React from 'react';
import { FormOutlinedInput, RadioButtonGroup } from '../../components';
import VariationChooser from '../../containers/variation-chooser-container';
import { useProviderState } from '../../context/provider-context';
import { dayAd, weekAd } from '../../lib/consts/radio-button-options';
import { OrderBatch } from '../../types/order-batch';

interface ProductFieldsProps {
  batch: BatchState;
  setBatch: (value: React.SetStateAction<BatchState>) => void;
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
  };
}

interface BatchState extends OrderBatch {
  radioValue: string;
}

const OrderProductFields: React.FC<ProductFieldsProps> = ({
  batch,
  setBatch,
  floatingOrder,
}: ProductFieldsProps) => {
  const providerState = useProviderState();

  const handleVariationToBatch = (variation: string, value: boolean): void => {
    // jos false poistetaan
    if (!value)
      setBatch((prevBatch) => ({
        ...prevBatch,
        variations: prevBatch.variations.filter((v) => v !== variation),
      }));
    else {
      setBatch((prevBatch) => ({
        ...prevBatch,
        variations: [...prevBatch.variations, variation],
      }));
    }
  };

  const handleQuantity = (event: any, value: string): void => {
    const name = event.target.name;
    if (name === 'length') {
      setBatch((prevBatch) => ({
        ...prevBatch,
        quantity: value !== 'other' ? +value : 0,
        radioValue: `${value}`,
      }));
      return;
    }
    if (event.target)
      setBatch({
        ...batch,
        quantity: +event.target.value,
      });
  };

  const product = providerState.product;

  const validateQuantity = (): string => {
    const product = providerState.product;
    if (!product) return 'Ei tuotetta';
    if (floatingOrder && floatingOrder.floating) {
      if (product.limitType === 'month') {
        if (floatingOrder.quantity > 0) return '';
        return 'Virhe';
      }
      if (floatingOrder.quantity >= 5 && floatingOrder.quantity % 5 === 0) return '';
      return 'Arvon tulee olla jaollinen viidellä';
    }
    if (product.limitType === 'month') {
      if (batch.quantity > 0) return '';
      return 'Virhe';
    }
    if (product.limitType === 'week') {
      if (batch.quantity >= 10 && batch.quantity % 10 === 0) return '';
      return 'Arvon tulee olla jaollinen kymmenellä';
    }
    if (batch.quantity >= 5 && batch.quantity % 5 === 0) return '';
    return 'Arvon tulee olla jaollinen viidellä';
  };

  const getQuantity = (value: any): any => {
    const limitType = product?.limitType;
    if (floatingOrder && floatingOrder.floating) {
      const radioLengths = limitType === 'week' ? [10, 20] : [5, 10];
      if (radioLengths.includes(floatingOrder.quantity))
        return {
          name: value,
          value: `${floatingOrder.quantity}`,
          onChange: handleQuantity,
        };

      if (value === 'length') {
        return {
          name: value,
          value: `other`,
          onChange: handleQuantity,
        };
      }
      return {
        name: value,
        value: floatingOrder.quantity,
        onChange: handleQuantity,
      };
    }
    if (value === 'length')
      return { name: value, value: batch.radioValue, onChange: handleQuantity };
    return { name: value, value: batch.quantity, onChange: handleQuantity };
  };

  return (
    <Grid container item>
      {product?.limitType === 'custom' ? (
        <></>
      ) : product?.limitType !== 'month' ? (
        <RadioButtonGroup
          getFieldProps={getQuantity}
          disabled={!!floatingOrder?.floating}
          name="length"
          {...(product?.limitType === 'second' ? dayAd : weekAd)}
          fieldError={validateQuantity()}
        ></RadioButtonGroup>
      ) : (
        product?.minOrderQuantity > 1 && (
          <FormOutlinedInput
            name="length"
            limitType={product?.limitType}
            setFieldValue={(name: string, value: string): void =>
              setBatch({ ...batch, quantity: +value })
            }
            min={5}
          ></FormOutlinedInput>
        )
      )}
      {product?.variations && product?.variations?.length > 1 && (
        <VariationChooser
          variations={product?.variations}
          setFieldValue={handleVariationToBatch}
          touched={true}
          selectedVariations={
            floatingOrder && floatingOrder.floating ? floatingOrder.variations : batch.variations
          }
          maxVariations={product?.maxVariations}
          floating={!!floatingOrder?.floating}
        ></VariationChooser>
      )}
    </Grid>
  );
};

export default OrderProductFields;
