import { CampaignPriceRow, ReservationInterface } from '../interfaces';

type Month = {
  year: number;
  month: number;
};

type Week = {
  year: number;
  week: number;
};

export type OrderBatch = {
  id: number;
  variations: string[];
  quantity: number;
  customName: boolean;
  name?: string;
  dates?: Date[];
  weeks?: Week[];
  months?: Month[];
  unitAmount: number; //date month ja muu maara
};

const parseOrderBatch = (
  {
    id,
    variations,
    name,
    dates,
    weeks,
    months,
    quantity = 0,
    customName = false,
  }: Partial<OrderBatch>,
  limit?: String
): OrderBatch => {
  let unitAmount = 0;
  if (typeof id !== 'number') throw new Error('Invalid id');
  if (!variations) throw new Error('Invalid variations');
  if (dates && dates.length) {
    unitAmount = dates.length;
  } else if (weeks && weeks.length) {
    unitAmount = weeks.length > 0 ? weeks.length : 0;
  } else if (months && months.length) {
    unitAmount = months.length > 0 ? months.length : 0;
  } else if (limit === 'custom') {
    unitAmount = 1;
  }
  return {
    id,
    name,
    variations,
    dates,
    weeks,
    months,
    quantity,
    customName,
    unitAmount,
  };
};

const parseReservationToOrderBatch = ({
  reservation: { batch, date, week, month, year, quantity, productVariationId },
  priceRows,
}: {
  reservation: ReservationInterface;
  priceRows: CampaignPriceRow[];
}): OrderBatch => {
  return {
    id: +batch,
    dates: date ? [new Date(date)] : date,
    weeks: week && year ? [{ week, year }] : undefined,
    months: month && year ? [{ month, year }] : undefined,
    quantity,
    variations: [productVariationId],
    customName: !!priceRows.find((row) => +`${row.batch}` === batch)?.customName,
    unitAmount: 1,
    name: priceRows.find((row) => +`${row.batch}` === batch)?.productName,
  };
};

const parseReservationsToOrderBatches = ({
  reservations,
  priceRows,
}: {
  reservations: ReservationInterface[];
  priceRows: CampaignPriceRow[];
}): OrderBatch[] => {
  const batches: OrderBatch[] = [];
  reservations.forEach((reservation) => {
    const batch = parseReservationToOrderBatch({ reservation, priceRows });
    const sameBatch = batches.find((eBatch) => eBatch.id === batch.id);
    if (sameBatch) {
      if (batch.weeks) {
        if (
          sameBatch.weeks &&
          sameBatch.weeks.find((week) => {
            return week.week === reservation.week && week.year === reservation.year;
          })
        ) {
          if (!sameBatch.variations.includes(reservation.productVariationId))
            sameBatch.variations.push(batch.variations[0]);
        } else sameBatch.weeks?.push(batch.weeks[0]);
      } else if (batch.months) {
        if (
          sameBatch.months &&
          sameBatch.months.find((month) => {
            return month.month === reservation.month && month.year === reservation.year;
          })
        ) {
          if (!sameBatch.variations.includes(reservation.productVariationId))
            sameBatch.variations.push(batch.variations[0]);
        } else sameBatch.months?.push(batch.months[0]);
      } else if (batch.dates) {
        if (
          sameBatch.dates &&
          sameBatch.dates.find(
            (date) => date.getTime() === (batch.dates ? batch.dates[0].getTime() : 0)
          )
        ) {
          if (!sameBatch.variations.includes(reservation.productVariationId))
            sameBatch.variations.push(batch.variations[0]);
        } else sameBatch.dates?.push(batch.dates[0]);
      }
    } else batches.push(batch);
  });

  return batches;
};

export { parseOrderBatch, parseReservationsToOrderBatches, parseReservationToOrderBatch };
