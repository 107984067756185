/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, makeStyles, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import LoadingSpinner from '../../loader';

const useStyles = makeStyles(() => ({
  submitButton: {
    backgroundColor: '#5E8EFC',
    marginLeft: '50px',
  },
  submitText: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  greyButton: {
    backgroundColor: '#707070',
    marginLeft: '25px',
  },
}));

interface Props {
  sendOffer: () => void;
  sendOrder: () => void;
  sendOrderPdf: () => void;
  sending: boolean;
  approveOffer?: boolean;
  edit?: boolean;
  limitType?: string;
}

const OrderButtonsStep: React.FC<Props> = ({
  sendOffer,
  sendOrderPdf,
  sendOrder,
  sending,
  approveOffer = false,
  edit = false,
  limitType,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      {!approveOffer && !edit ? (
        limitType !== 'custom' && (
          <Button
            className={classes.submitButton}
            variant="contained"
            onClick={sendOffer}
            classes={{ root: classes.greyButton }}
          >
            <Typography className={classes.submitText}>Tallenna Tarjouksena</Typography>
          </Button>
        )
      ) : (
        <Button
          className={classes.submitButton}
          variant="contained"
          onClick={(): Promise<void> => navigate(edit ? '/orders' : '/offers')}
          classes={{ root: classes.greyButton }}
        >
          <Typography className={classes.submitText}>Peruuta</Typography>
        </Button>
      )}
      {limitType === 'custom' && !edit ? (
        <></>
      ) : (
        <Button className={classes.submitButton} variant="contained" onClick={sendOrder}>
          <Typography className={classes.submitText}>
            {edit ? 'Tallenna muutokset' : 'Lähetä Tilaus'}
          </Typography>
        </Button>
      )}
      <Button className={classes.submitButton} variant="contained" onClick={sendOrderPdf}>
        <Typography className={classes.submitText}>
          {edit ? 'Tallenna muutokset ja lataa PDF:nä' : 'Lähetä tilaus ja lataa PDF:nä'}
        </Typography>
      </Button>
      <LoadingSpinner showSpinner={sending}></LoadingSpinner>
    </>
  );
};

export default OrderButtonsStep;
