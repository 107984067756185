import { Button, Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import OrderBatchContainer from '../containers/order-batch-container';
import ProductChooser from '../containers/product-chooser-container';
import SettingChoosers from '../containers/setting-choosers-container';
import { useAddOrderDispatch, useAddOrderState } from '../context/add-order-context';
import { useOrderFormDispatch, useOrderFormState } from '../context/order-form-context';
import { useProviderDispatch, useProviderState } from '../context/provider-context';
import { CampaignInterface, OrderDetailsFormValues } from '../interfaces';
import productClient from '../lib/product-client';
import { parseOrderBatch } from '../types/order-batch';
import { mapSettingsValidations } from '../utils/validation-utils';
import FloatingOrderChooser from './order/order-details/floating-order-chooser';

const useStyles = makeStyles(() => ({
  submitButton: {
    backgroundColor: '#5E8EFC',
    marginLeft: '50px',
    color: 'white',
  },
  submitText: {
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 700,
  },
  greyButton: {
    backgroundColor: '#707070',
    marginLeft: '25px',
    color: 'white',
    fontWeight: 700,
  },
}));

interface OrderDetailsProps {
  submitting: boolean;
  setSubmitting: Function;
  handleSubmit: Function;
  setDetailErrors: Function;
  setSummaryTypeFormat: Function;
  campaignData?: CampaignInterface;
}

interface Errors {
  [key: string]: any;
}

const OrderDetailsForm: React.FC<OrderDetailsProps> = ({
  submitting,
  setSubmitting,
  handleSubmit,
  setDetailErrors,
  setSummaryTypeFormat,
  campaignData,
}: OrderDetailsProps) => {
  const classes = useStyles();

  const [dateChanged, setDateChanged] = useState(false);
  const [details, setDetails] = useState<OrderDetailsFormValues>();
  const [validation, setValidation] = useState<Errors>();
  const [batchErrors, setBatchErrors] = useState<number[]>([]);

  const addOrderDispatch = useAddOrderDispatch();
  const addOrderState = useAddOrderState();
  const providerDispatch = useProviderDispatch();
  const providerState = useProviderState();
  const orderFormDispatch = useOrderFormDispatch();
  const orderFormState = useOrderFormState();

  const validationSchema = Yup.object({
    product: Yup.string().required('Pakollinen kenttä'),
  });

  //variaatioiden paivitys
  const addBatch = (productId?: string, limitType?: String): void => {
    const orderBatches = productId ? [] : orderFormState.batches;
    const initBatch = {
      variations:
        addOrderState.floatingOrder && addOrderState.floatingOrder.floating
          ? addOrderState.floatingOrder?.variations
          : [],
      quantity:
        addOrderState.floatingOrder && addOrderState.floatingOrder.floating
          ? addOrderState.floatingOrder?.floatingAmount
          : 0,
      months: [],
      weeks: [],
      dates: [],
      customName: false,
      name: '',
      id: new Date().getTime(),
    };

    const limit = limitType || addOrderState.limitType;

    if (limit === 'custom') {
      initBatch.quantity = 1;
    }
    const newBatches = [...orderBatches, initBatch];
    orderFormDispatch({
      type: 'updateBatches',
      payload: newBatches.map((newBatch) => parseOrderBatch(newBatch, limit)),
    });
  };

  const deleteBatch = (batchId: number): void => {
    const orderBatches = orderFormState.batches;
    const newBatches = orderBatches.filter((batch) => batch.id !== batchId);
    if (!window.confirm('Poistetaanko jakso?')) return;
    orderFormDispatch({
      type: 'updateBatches',
      payload: newBatches.map((batch) => parseOrderBatch(batch)),
    });
  };

  const addBatchError = (batchId: number): void => {
    setBatchErrors([...new Set([...batchErrors, batchId])]);
  };

  const removeBatchError = (batchId: number): void => {
    setBatchErrors(batchErrors.filter((error) => error !== batchId));
  };

  const campaignSettings = () => {
    const campaignDetails = campaignData?.campaignDetails;

    if (campaignDetails) {
      const details: {
        [key: string]: string | { [key: string]: boolean };
      } = {};
      campaignDetails.forEach((detail) => {
        const split = detail.value.split(',');
        if (split.length > 1) {
          const checkBoxObject: { [key: string]: boolean } = {};

          split.forEach((value) => {
            checkBoxObject[value.toString()] = true;
          });

          return (details[detail.key] = checkBoxObject);
        }
        details[detail.key] = detail.value.toString();
      });
      return details;
    }
  };

  const onSubmit = (): void => {
    handleSubmit(details);
  };

  const formik = useFormik({
    initialValues:
      campaignData && addOrderState
        ? {
            product: addOrderState.productId,
            ...campaignSettings(),
          }
        : {
            product: '',
          },
    enableReinitialize: true,
    validationSchema: validation,
    validateOnChange: true,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    const selectedProduct = providerState.product;
    if (selectedProduct) {
      setBatchErrors([]);
      if (!campaignData && selectedProduct.productSettings) {
        selectedProduct.productSettings.forEach((setting): void => {
          const tempValues: { [key: string]: boolean } = {};
          if (setting.options) {
            setting.options.map((option): void => {
              if (option.default) {
                tempValues[option.value] = true;
                if (setting.type === 'radio') formik.setFieldValue(setting.id, option.value);
              }
            });
            if (setting.type === 'checkbox') formik.setFieldValue(setting.id, tempValues);
          }
        });
      }
    }
    const schema = mapSettingsValidations(selectedProduct?.productSettings || []);

    setValidation(validationSchema.concat(schema));
    if (!selectedProduct) return;
    // tuotteen vaihto tyhjaa batsit contextista vasta kun context on nollattu lisataan uusi batsi
    // pidetaan aina vahintaan yksi batchi tilassa
    //tehdaan tarkistus jo olemassa olevat batsit kuuluu jo tuotteeseen
    if (orderFormState) {
      const seVariations = selectedProduct.variations;
      if (seVariations) {
        const vars = orderFormState.batches.map((batch) => batch.variations).flat();
        const variations = [...new Set(vars)];
        const check = variations.every((variation) =>
          seVariations.map((vari) => vari.id).includes(variation)
        );
        if (variations.length > 0 && check) return;
      }
    }

    addBatch(selectedProduct.id, selectedProduct.limitType);
  }, [providerState.product, providerState.productId]);

  const handleFloatingProduct = async (): Promise<void> => {
    const floatingOrder = addOrderState.floatingOrder;
    if (campaignData && floatingOrder && floatingOrder.variations && formik.values.product === '') {
      const provider = await productClient.getProvider(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PROVIDER_UUID || ''
      );
      const product = provider.products.find(
        (product) =>
          product.variations &&
          product.variations.map((vari) => vari.id || '').includes(floatingOrder?.variations[0])
      );
      if (product) formik.setFieldValue('product', product.id);
    }
  };

  useEffect(() => {
    if (addOrderState.floatingOrder && addOrderState.floatingOrder.floating)
      handleFloatingProduct();
  }, [campaignData, addOrderState]);

  useEffect(() => {
    //formik.resetForm();
    if (providerState.productId) formik.setFieldValue('product', providerState.productId);
  }, [providerState.productId]);

  useEffect(() => {
    const product = providerState.product;
    if (!product) return;

    addOrderDispatch({
      type: 'update',
      payload: {
        limitType: product.limitType,
        productId: product.id,
        productName: product.name,
      },
    });
  }, [providerState.product]);

  useEffect(() => {
    if (submitting) {
      setSubmitting(false);
      formik.submitForm();
    }
  }, [submitting]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setDetailErrors(true);
      return;
    }

    if (batchErrors.length > 0) {
      setDetailErrors(true);
      return;
    }

    if (addOrderState.floatingOrderError && addOrderState.floatingOrderError.length > 0) {
      setDetailErrors(true);
      return;
    }
    setDetailErrors(false);
  }, [formik.errors, batchErrors]);

  useEffect(() => {
    orderFormDispatch({
      type: 'updateProductId',
      payload: formik.values.product,
    });
    if (formik.values.product != providerState.productId)
      providerDispatch({
        type: 'update',
        payload: { productId: formik.values.product },
      });
  }, [formik.values.product]);

  useEffect(() => {
    const array: {
      key: string;
      value: string | {} | { [key: string]: boolean };
      settingId: string;
      label: string;
    }[] = [];
    Object.entries(formik.values).forEach(([key, value]) => {
      const setting = providerState.product?.productSettings?.find((setting) =>
        key.includes(setting.id)
      );
      if (setting) {
        array.push({ key, value, settingId: setting.id, label: setting.label });
      }
    });

    //paska
    addOrderDispatch({
      type: 'update',
      payload: {
        noOffset: array.some((item) => item.value.toString().toLowerCase().includes('valmis')),
      },
    });

    const temp = {
      product: formik.values.product,
      campaignDetails: [...array],
    };
    setDetails((prevDetails) => ({ ...prevDetails, ...temp }));

    setDateChanged(false);
  }, [formik.values, dateChanged, providerState.product, addOrderDispatch]);

  useEffect(() => {
    setSummaryTypeFormat({
      settingValues: details?.campaignDetails,
      settings: providerState.product?.productSettings,
      type: providerState.product?.name,
    });
  }, [details, providerState.product, setSummaryTypeFormat]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container style={{ width: '900px', marginLeft: '10px' }}>
        <Grid container item>
          <ProductChooser
            name="product"
            getFieldProps={formik.getFieldProps}
            title="Mainostyyppi"
            setProductId={(id = ''): void => {
              providerDispatch({ type: 'update', payload: { productId: id } });
            }}
            campaignData={campaignData ? true : false}
          ></ProductChooser>
        </Grid>
        {!providerState.product?.noFloating && (
          <FloatingOrderChooser product={providerState.product} campaignData={campaignData} />
        )}
        <div
          style={{
            width: '100%',
            margin: '10px',
            height: '5px',
            borderTop: '1px solid #bbb',
          }}
        />
        {orderFormState.productId !== undefined &&
          orderFormState.batches
            .sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((batch, index) => (
              <div key={`batch-${batch.id}`}>
                {index !== 0 && (
                  <Button
                    className={classes.greyButton}
                    type="button"
                    onClick={(): void => deleteBatch(batch.id)}
                  >
                    Poista jakso
                  </Button>
                )}
                {providerState.product && (
                  <OrderBatchContainer
                    batchIndex={index}
                    product={providerState.product}
                    campaignData={campaignData}
                    setErrors={addBatchError}
                    clearErrors={removeBatchError}
                    orderBatch={batch}
                    floatingOrder={addOrderState.floatingOrder}
                  />
                )}
                <div
                  style={{
                    width: '100%',
                    margin: '10px',
                    height: '5px',
                    borderTop: '1px solid #bbb',
                  }}
                />
              </div>
            ))}

        <Button className={classes.submitButton} type="button" onClick={(): void => addBatch()}>
          Lisää jakso
        </Button>
        <Grid container item>
          <SettingChoosers
            getFieldProps={formik.getFieldProps}
            productSettings={providerState.product?.productSettings}
            setFieldValue={formik.setFieldValue}
            fieldErrors={formik.errors}
            touched={formik.touched}
          ></SettingChoosers>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrderDetailsForm;
