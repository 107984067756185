import {
  Button,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { navigate } from '@reach/router';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import {
  CampaignInterface,
  ProductVariationInterface,
  ProviderInterface,
  ReservationInterface,
} from '../../interfaces';
import campaignClient from '../../lib/campaign-client';
import customerClient from '../../lib/customer-client';
import { sortMonthReservations, sortWeekReservations } from '../../utils/reservation-utils';
import { capitalizeString, doubleDecimalString } from '../../utils/string-utils';
import LogTable from './campaign-row/log-table';
import PricerowTable from './campaign-row/pricerow-table';
import ReservationTable from './campaign-row/reservation-table';
import CampaignStatusSelect from './campaign-status-select';
import ApproveModal from './offer-approve-modal';

interface ReservationGroup {
  date?: Date;
  month?: number;
  year?: number;
  variations: string[];
}

interface Props {
  singleCampaign?: CampaignInterface;
  type: string;
  variations: ProductVariationInterface[];
  refresh: () => Promise<void>;
  provider?: ProviderInterface;
  admin?: boolean;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  popperContainer: {
    background: 'white',
    marginTop: '10px',
  },
  detailTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.21px',
  },
  detaiValue: {
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  detailContainer: {
    margin: '5px',
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  button: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: '#5E8EFC',
    boxShadow: '0px 1px 3px #00000033',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(94, 142, 252,0.7)',
    },
  },
  headerRow: {
    backgroundColor: '#FAFAFB',
    color: '#000000',
  },
  logTable: {
    marginTop: '20px',
  },
});

const OfferCampaing: React.FC<Props> = ({
  singleCampaign,
  type,
  variations,
  refresh,
  provider,
  admin = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const classes = useRowStyles();
  const [sortedReservations, setSortedReservations] = useState<ReservationInterface[]>([]);
  const [campaign, setCampaign] = useState(singleCampaign);
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  // Ref for the "sentinel" element that will be used for intersection observing
  const sentinelRef = useRef(null);
  // Function to fetch data when the component is visible
  const fetchData = async (id: string) => {
    try {
      if (campaign?.customerId && campaign.id) {
        const response = await customerClient.findCustomersById(campaign.customerId, campaign.id);

        if (response)
          setCampaign({ ...campaign, customer: response, customerName: response.customerName });
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  // Intersection observer callback function
  const handleIntersection = async (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;

    if (entry.isIntersecting && isLoading && !campaign?.customer) {
      // When the "sentinel" element is visible, fetch more data
      await fetchData(entry.target.id);
    }
  };

  // Set up the intersection observer
  useEffect(() => {
    if (sentinelRef.current) {
      const options = {
        root: null, // viewport as the root
        rootMargin: '0px', // no margin
        threshold: 1.0, // fully visible
      };

      const observer = new IntersectionObserver(handleIntersection, options);
      if (campaign?.customer) setIsLoading(false);
      if (sentinelRef && sentinelRef.current) {
        // @ts-expect-error
        observer.observe(sentinelRef.current);
      }

      // Clean up the observer when the component unmounts
      return () => {
        observer.disconnect();
      };
    }
  }, [isLoading]);

  useEffect(() => {
    if (campaign && campaign.reservations) {
      if (campaign.isMonth) {
        const reservationsCopy = [...campaign.reservations];
        if (reservationsCopy.length > 0) {
          const sorted = sortMonthReservations(reservationsCopy);
          setSortedReservations([...sorted]);
        }
      } else {
        if (campaign.reservations.length > 0) {
          const first = campaign.reservations[0];
          if (first.week) setSortedReservations(sortWeekReservations(campaign.reservations));
          else {
            setSortedReservations(
              campaign.reservations.sort((a, b) => (a.date && b.date && a.date > b.date ? 1 : -1))
            );
          }
        }
      }
    }
  }, [campaign]);

  if (!campaign) return null;

  const {
    id,
    campaignName,
    customer,
    customerEmail,
    customerName = '-',
    materialApproverEmail,
    materialSupplierEmail,
    materialApproverPhone,
    materialSupplierPhone,
    contactPerson: customerPerson,
    campaignPrice,
    campaignDetails,
    reservations = [],
    jobNumber,
    user,
    isMonth,
    status,
    freeText = '',
    campaignNumber,
    invoiceInstruction,
  } = campaign;

  const {
    businessid = '-',
    address = '-',
    invoiceDiscount = '-',

    invoiceAddresses,
  } = customer || {};

  if (!id) return null;

  const { totalPrice = 0, vat = 0, discount = 0, listPrice = 0 } = campaignPrice || {};

  const companyDetails = [
    { label: 'Toiminimi', value: customerName },
    { label: 'Asiakastunnus', value: businessid ? businessid : '-' },
    { label: 'Alennusprosentti', value: invoiceDiscount + '%' },
    { label: 'Toimitusosoite', value: address },
    {
      label: 'Laskutusosoite',
      value: invoiceAddresses && invoiceAddresses[0] ? invoiceAddresses[0].invoice_address : '',
    },
  ];

  const contactPersonDetails = [
    { label: 'Tilaajan nimi', value: customerPerson },
    { label: 'Tilaajan sähköposti', value: customerEmail },
    { label: 'Aineiston toimittaja', value: materialSupplierEmail },
    {
      label: 'Aineiston toimittajan puhelinnumero',
      value: materialSupplierPhone,
    },
    { label: 'Aineiston hyväksyjä', value: materialApproverEmail },
    {
      label: 'Aineiston hyväksyjän puhelinnumero',
      value: materialApproverPhone,
    },
  ];

  let material = '-';

  const adLengths = reservations.map((reservation) => reservation.quantity);
  const adLength = [...new Set(adLengths)].join(', ');
  const orderDetails = [
    {
      label: 'Mainoksen pituus',
      value: `${reservations[0] ? adLength + (isMonth ? ' kappaletta' : ' sekuntia') : '-'}`,
    },
    { label: 'Työnumero', value: jobNumber ? jobNumber : '-' },
    { label: 'Tilausnumero', value: campaignNumber ? campaignNumber : '-' },
    ...campaignDetails.map((detail) => {
      if (detail.label === 'Aineisto') material = detail.value;
      return { label: detail.label, value: detail.value };
    }),
    {
      label: 'Laskutusohje',
      value: invoiceInstruction ? invoiceInstruction : '',
      html: true,
    },
  ];

  orderDetails.push({ label: 'Vapaakenttä', value: freeText, html: true });

  orderDetails.push({
    label: 'Lisäinfo Sisu-tiimille',
    value: `${campaign.additionalInfo ? campaign.additionalInfo : ''}`,
  });

  const product = campaignPrice.priceRows[0]?.productId;

  let orderDetails2 = [
    { label: 'Hinta ovh', value: `${doubleDecimalString(listPrice)}€` },
    {
      label: 'Asiakaskohtainen alennus',
      value: `${
        (discount / listPrice) * 100 ? doubleDecimalString((discount / listPrice) * 100) : 0
      }% / -${doubleDecimalString(discount)}€`,
    },
    {
      label: 'Mediatoimistoalennus 15%',
      value: `${
        campaignPrice.mediaDiscount
          ? doubleDecimalString(campaignPrice.mediaDiscount) + '€'
          : 'Ei annettu'
      }`,
    },
    {
      label: 'Yhteensä Alv 0%',
      value: `${doubleDecimalString(listPrice - discount - campaignPrice.mediaDiscount)}€`,
    },
    { label: 'Yhteensä Alv 25,5%', value: `${doubleDecimalString(vat)}€` },
    { label: 'Yhteensä', value: `${doubleDecimalString(totalPrice)}€` },
  ];

  const mapDetails = (array: { label: string; value: string | number; html?: boolean }[]): any => {
    return array.map((detail) => (
      <Grid className={classes.detailContainer} key={detail.label} container direction="column">
        <Typography className={classes.detailTitle}>{detail.label}</Typography>

        {detail.html ? (
          <div
            className={classes.detaiValue}
            dangerouslySetInnerHTML={{ __html: detail.value ? detail.value + '' : '' }}
          ></div>
        ) : (
          <Typography>{capitalizeString(detail.value)}</Typography>
        )}
      </Grid>
    ));
  };

  const onClickNew = (): void => {
    navigate('/ordertemplate', { state: { singleCampaign: campaign } });
  };

  const onClickUpdate = (): void => {
    navigate('/orderUpdate', { state: { singleCampaign: campaign } });
  };

  const onRemove = async (id?: string): Promise<void> => {
    if (!id) return;
    const confirmation = window.confirm(
      'Oletko varma että tilaus varauksineen poistetaan järjestelmästä?'
    );
    if (confirmation) {
      try {
        await campaignClient.removeCampaign(id);
        window.location.reload();
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const onClickConfirm = async (id?: string): Promise<void> => {
    if (!id) return;
    const confirmation = window.confirm('Oletko varma että tilaus viedään mopoon?');
    if (confirmation) {
      try {
        await campaignClient.confirmCampaign(id);
        window.location.reload();
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const onClicApprove = (): void => {
    navigate('/approveoffer', { state: { singleCampaign: campaign } });
  };

  const mapVariationLabel = (): string => {
    let variationIds: string[] = [];
    campaign.reservations?.forEach((reservation) => {
      if (reservation.productVariationId) variationIds.push(reservation.productVariationId);
    });

    variationIds = variationIds.filter((v, i, a) => a.indexOf(v) === i);

    const labels = variationIds
      .map((a) => {
        const variation = variations.find((variation) => variation.id === a);
        return variation;
      })
      .sort((a, b) => {
        return ('' + a?.name).localeCompare('' + b?.name);
      });

    let variationLabel = '';

    labels.forEach((label, i) => {
      if (!label) return;
      variationLabel = variationLabel + label?.name + (i === labels.length - 1 ? '' : ', ');
    });

    if (labels.length === 3) variationLabel = 'Koko päivä';

    return variationLabel;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlePopper = (event: any) => {
    setAnchorEl(event.currentTarget);
    setPopperOpen(!popperOpen);
  };

  const handleClose = (event: any): void => {
    setPopperOpen(false);
  };

  const campaignPeriod = (): string => {
    if (!sortedReservations || sortedReservations.length === 0) return '-';
    const first = sortedReservations[0];
    const last = sortedReservations[sortedReservations.length - 1];
    if (isMonth) {
      const first = sortedReservations[0];
      const last = sortedReservations[sortedReservations.length - 1];
      return first.month + '/' + first.year + '-' + last.month + '/' + last.year;
    }
    if (first.week && last.week) {
      return `${first.week}/${first.year}-${last.week}/${last.year}`;
    }
    return `${moment(first.date).format('DD.MM.')}-${moment(last.date).format('DD.MM.YYYY')}`;
  };

  const showReservationTable = campaign.campaignPrice.priceRows[0].productId !== 'Nysse/Föli';

  return (
    <>
      {type === 'offer' && (
        <ApproveModal
          open={ModalOpen}
          setOpen={setModalOpen}
          id={id}
          customer={customerName}
          campaign={campaignName}
        ></ApproveModal>
      )}
      <TableRow className={classes.root} classes={{ root: classes.root }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={(): void => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell
          ref={sentinelRef}
          id={campaign.customerId}
          align="left"
          className={classes.boldCell}
        >
          {isLoading && !customerName ? (
            <Loader type="ThreeDots" color="#E30613" height={50} width={50}></Loader>
          ) : (
            customerName
          )}
        </TableCell>
        <TableCell className={classes.detaiValue}>{campaignName}</TableCell>
        <TableCell align="left" className={classes.detaiValue}>
          <Typography>{campaignPeriod()}</Typography>
          <Typography>{mapVariationLabel()}</Typography>
        </TableCell>
        <TableCell align="left" className={classes.detaiValue}>
          {user ? user.name : '-'}
        </TableCell>
        {type !== 'offer' && (
          <>
            <TableCell classes={{ root: classes.root }} align="left">
              {`${campaignNumber}${jobNumber ? ` / ${jobNumber}` : ''}`}
            </TableCell>
            {status !== undefined && (
              <CampaignStatusSelect
                refresh={refresh}
                admin={admin}
                campaignId={id}
                status={status}
              />
            )}
            <TableCell align="left" className={classes.detaiValue}>
              {capitalizeString(material)}
            </TableCell>
          </>
        )}
        <TableCell classes={{ root: classes.root }} align="left">
          <Button
            disabled={!campaign.customer}
            onClick={handlePopper}
            variant="outlined"
            className={classes.button}
          >
            Toiminnot
          </Button>
          <Popper id={`popper-button${campaign.id}`} open={popperOpen} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={handleClose}>
              <Grid
                className={classes.popperContainer}
                container
                justifyContent="center"
                direction="column"
                spacing={1}
              >
                {type === 'offer' && (
                  <Grid item>
                    <Button onClick={onClicApprove} variant="outlined" className={classes.button}>
                      Siirry Hyväksymään
                    </Button>
                  </Grid>
                )}
                {type !== 'offer' && customer && admin && customer.id !== '-1' && (
                  <Grid item>
                    <Button
                      onClick={(): Promise<void> => onRemove(campaign.id)}
                      variant="outlined"
                      className={classes.button}
                    >
                      Poista
                    </Button>
                  </Grid>
                )}
                {type !== 'offer' && campaign.isUpdatable && !campaign.jobNumber && (
                  <Grid item>
                    <Button onClick={onClickUpdate} variant="outlined" className={classes.button}>
                      Muokkaa
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Button onClick={onClickNew} variant="outlined" className={classes.button}>
                    Käytä pohjana
                  </Button>
                </Grid>
                {type !== 'offer' &&
                  campaign.isUpdatable &&
                  !campaign.jobNumber &&
                  admin &&
                  !campaign.confirmed && (
                    <Grid item>
                      <Button
                        onClick={() => onClickConfirm(campaign.id)}
                        variant="outlined"
                        className={classes.button}
                      >
                        Siirrä mopoon
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </ClickAwayListener>
          </Popper>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={15} style={{ paddingBottom: '20px', paddingTop: 0, width: '100%' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#FAFAFB' }}>
                  <TableCell className={classes.detailTitle}>Yritys</TableCell>
                  <TableCell className={classes.detailTitle}>Yhteyshenkilöt</TableCell>
                  <TableCell className={classes.detailTitle}>Tilauksen Yhteenveto</TableCell>
                  <TableCell className={classes.detailTitle}>Tilauksen Yhteenveto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {mapDetails(companyDetails)}
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {mapDetails(contactPersonDetails)}
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {mapDetails(orderDetails)}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {mapDetails(orderDetails2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {campaign && showReservationTable && (
              <ReservationTable singleCampaign={campaign} provider={provider} />
            )}
            {campaign && !showReservationTable && (
              <PricerowTable singleCampaign={campaign}></PricerowTable>
            )}
            {type !== 'offer' && <LogTable singleCampaign={campaign} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OfferCampaing;
