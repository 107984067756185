/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAddOrderDispatch, useAddOrderState } from '../../context/add-order-context';
import { useOrderFormState } from '../../context/order-form-context';
import { AvailabilityInterface, Month, ProductInterface, Week } from '../../interfaces';
import reservationClient from '../../lib/reservation-client';
import { OrderBatch } from '../../types/order-batch';
import { mapReservations } from '../../utils/reservation-utils';
import MonthPicker from '../month-picker/month-picker';
import WeekPicker from '../week-picker/week-picker';
import OrderDatePicker from './order-date-picker';

interface OrderDetailsReservationsProps {
  batch: OrderBatch;
  floating?: {
    quantity: number;
    variations: string[];
  };
  setBatch: Function;
  product: ProductInterface;
  orderTemplate: boolean;
  periodError: string;
  campaingId: string;
  batchIndex: number;
}

const useStyles = makeStyles(() => ({
  dateTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  dateSubTitle: { marginLeft: '22px', fontSize: '12px', lineHeight: '1px' },
  dateTitleContainer: {
    height: '100%',
  },
}));
const StyledError = styled.h5`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 16px;
  font-weight: 400;
  color: #e30312;
`;
const OrderDetailsReservations: React.FC<OrderDetailsReservationsProps> = ({
  batch,
  setBatch,
  product,
  orderTemplate,
  periodError,
  campaingId,
  floating,
  batchIndex,
}: OrderDetailsReservationsProps) => {
  const [mappedReservations, setMappedReservations] = useState<AvailabilityInterface>();
  const [startDate, setStartDate] = useState(moment());
  const classes = useStyles();
  const addOrderState = useAddOrderState();
  const orderFormState = useOrderFormState();
  const addOrderDispatch = useAddOrderDispatch();

  useEffect(() => {
    setMappedReservations(undefined);
  }, [product.id]);

  useEffect(() => {
    let variations = batch.variations;
    if (floating) {
      variations = floating.variations;
    }
    const getReservations = async (): Promise<void> => {
      const mappedReservations = await getMappedReservations(
        moment(startDate).startOf('month').startOf('day').toDate(),
        moment(startDate).add(1, 'month').endOf('month').toDate()
      );
      setMappedReservations(mappedReservations);
    };

    getReservations();
  }, [batch.variations, startDate, floating, batch.quantity]);

  useEffect(() => {
    if (product.limitType === 'custom') setStartDate(moment().add(2, 'week'));
  }, []);

  const setDates = async (name: string, value: Date[]): Promise<void> => {
    //tehdaan logiikkaaa
    const { floatingOrder } = addOrderState;
    if (floatingOrder && floatingOrder.floating) {
      if (floatingOrder.floatingAmount) {
        if (batch.dates && value.length > batch.dates.length) {
          const dateAmount = orderFormState.batches.reduce(
            (cur: number, batch: OrderBatch): number => batch.unitAmount + cur,
            0
          );
          if (dateAmount > floatingOrder.floatingAmount) {
            addOrderDispatch({
              type: 'update',
              payload: {
                floatingOrderError: 'Liikaa varattuja päiviä kelluvassa varauksessa',
              },
            });
            return;
          }
          if (
            dateAmount >= floatingOrder.floatingAmount &&
            dateAmount - batch.dates.length + value.length > floatingOrder.floatingAmount
          ) {
            return;
          }
        }
      }
    }
    addOrderDispatch({
      type: 'update',
      payload: {
        floatingOrderError: '',
      },
    });
    setBatch({
      ...batch,
      [name]: value,
      months: undefined,
      weeks: undefined,
    });
  };

  useEffect(() => {
    const date = orderFormState.batches[0].dates?.[0];
    if (product.limitType === 'custom' && batchIndex && batchIndex > 0 && date) {
      setStartDate(moment(date));
      addOrderDispatch({
        type: 'update',
        payload: {
          floatingOrderError: '',
        },
      });
      setBatch({
        ...batch,
        dates: [date],
        months: undefined,
        weeks: undefined,
      });
    }
  }, [orderFormState.batches[0].dates?.[0]]);

  const setMonths = async (months: Month[]): Promise<void> => {
    //tehdaan logiikkaaa
    const { floatingOrder } = addOrderState;
    if (floatingOrder && floatingOrder.floating) {
      if (floatingOrder.floatingAmount) {
        if (batch.months && months.length > batch.months.length) {
          const dateAmount = orderFormState.batches.reduce(
            (cur: number, batch: OrderBatch): number => batch.unitAmount + cur,
            0
          );
          if (dateAmount > floatingOrder.floatingAmount) {
            addOrderDispatch({
              type: 'update',
              payload: {
                floatingOrderError: 'Liikaa varattuja kuukausia kelluvassa varauksessa',
              },
            });
            return;
          }
          if (
            dateAmount >= floatingOrder.floatingAmount &&
            dateAmount - batch.months.length + months.length > floatingOrder.floatingAmount
          ) {
            return;
          }
        }
      }
    }
    addOrderDispatch({
      type: 'update',
      payload: {
        floatingOrderError: '',
      },
    });
    setBatch({
      ...batch,
      dates: [],
      weeks: [],
      months,
      quantity: 1,
      radioValue: '',
    });
  };

  const setWeeks = async (weeks: Week[]): Promise<void> => {
    const { floatingOrder } = addOrderState;
    if (floatingOrder && floatingOrder.floating) {
      if (floatingOrder.floatingAmount) {
        if (batch.weeks && weeks.length > batch.weeks.length) {
          const weekAmount = orderFormState.batches.reduce(
            (cur: number, batch: OrderBatch): number => batch.unitAmount + cur,
            0
          );
          if (weekAmount > floatingOrder.floatingAmount) {
            addOrderDispatch({
              type: 'update',
              payload: {
                floatingOrderError: 'Liikaa varattuja viikkoja kelluvassa varauksessa',
              },
            });
            return;
          }
          if (
            weekAmount >= floatingOrder.floatingAmount &&
            weekAmount - batch.weeks.length + weeks.length > floatingOrder.floatingAmount
          ) {
            return;
          }
        }
      }
    }
    addOrderDispatch({
      type: 'update',
      payload: {
        floatingOrderError: '',
      },
    });
    setBatch({
      ...batch,
      dates: [],
      months: [],
      weeks,
    });
  };

  const getMappedReservations = async (
    startDate: Date,
    endDate: Date
  ): Promise<AvailabilityInterface> => {
    let variations = batch.variations;
    if (floating) {
      variations = floating.variations;
    }
    const temporary: Record<string, any[]> = {};
    Object.keys(temporary).map((key) => {
      if (batch.variations.includes(key)) return;
      delete temporary[key];
    });

    if (variations) {
      const promises = variations.map(async (key: string) => {
        const getReserv = await reservationClient.getReservationSummary(
          key,
          startDate,
          endDate,
          campaingId
        );
        temporary[key] = getReserv;
      });
      await Promise.all(promises);
    }
    const length = floating ? floating.quantity : batch.quantity;
    const limitMultiplier = 0.1;
    return mapReservations(temporary, length, limitMultiplier);
  };

  return (
    <Grid container item>
      <Grid
        style={{ marginLeft: product.limitType === 'custom' ? '' : '15px', marginTop: '20px' }}
        item
        container
      >
        {product.limitType === 'custom' && batchIndex === 0 && (
          <Grid item>
            <Grid container alignContent="flex-end" className={classes.dateTitleContainer}>
              <Typography className={classes.dateTitle}>
                {product.limitType === 'custom' ? 'Siirto mopoon' : 'Ajanjakso'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Grid container alignContent="center" className={classes.dateTitleContainer}>
            {product.limitType !== 'custom' && (
              <Typography className={classes.dateSubTitle}>{`Minimi ${product.minOrderQuantity} ${
                product.limitType === 'month'
                  ? 'kuukautta'
                  : product.limitType === 'week'
                  ? 'viikkoa'
                  : 'päivää'
              }`}</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {product.limitType === 'month' && (
        <MonthPicker
          variation={product.variations ? product.variations[0].id : ''}
          setOrderMonths={setMonths}
          campaignMonths={batch.months ? batch.months : []}
          // fieldError={formik.touched['months'] ? formik.errors.months : ''}
          fieldError={periodError}
          notifyUser={orderTemplate}
          campaignId={campaingId}
        ></MonthPicker>
      )}
      {product.limitType === 'week' && (
        <WeekPicker
          variation={product.variations ? product.variations[0].id : ''}
          setOrderWeeks={setWeeks}
          campaignWeeks={batch.weeks ? batch.weeks : []}
          // fieldError={formik.touched['months'] ? formik.errors.months : ''}
          fieldError={periodError}
          notifyUser={orderTemplate}
          campaignId={campaingId}
          quantity={batch.quantity}
        ></WeekPicker>
      )}
      {product.limitType === 'second' && (
        <OrderDatePicker
          setDates={setDates}
          mappedReservations={mappedReservations}
          startDate={startDate}
          setStartDate={setStartDate}
          name="dates"
          product={product}
          campaignDates={batch.dates ? batch.dates : []}
          fieldError={periodError}
          notifyUser={orderTemplate}
          noOffset={addOrderState.noOffset}
          getMappedReservations={getMappedReservations}
        ></OrderDatePicker>
      )}
      {product.limitType === 'custom' && (
        <div
          style={{
            display: batchIndex > 0 ? 'none' : 'block',
            marginBottom: '40px',
            paddingTop: '10px',
          }}
        >
          <DatePicker
            name="dates"
            value={startDate}
            disablePast
            onChange={(value: any) => {
              setStartDate(value.toDate());
              setDates('dates', [value.toDate()]);
            }}
          ></DatePicker>
          <StyledError>{periodError ? periodError : null}</StyledError>
        </div>
      )}
    </Grid>
  );
};

export default OrderDetailsReservations;
