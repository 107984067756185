import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { CampaignInterface, ProviderInterface, ReservationInterface } from '../../../interfaces';
import CTableCell from '../../table/table-cell';

interface ReservationGroup {
  date?: Date;
  month?: number;
  year?: number;
  week?: number;
  variations: string[];
}

interface Props {
  singleCampaign: CampaignInterface;
  provider?: ProviderInterface;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  headerRow: {
    backgroundColor: '#FAFAFB',
    color: '#000000',
  },
  logTable: {
    marginTop: '20px',
  },
});

const PricerowTable: React.FC<Props> = ({ singleCampaign }: Props) => {
  const classes = useRowStyles();

  return (
    <Table className={classes.logTable}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={classes.boldCell}>Kampanja jaksot</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {singleCampaign.campaignPrice.priceRows.map((res) => (
          <TableRow key={res.batch}>
            <CTableCell
              resolver={async (): Promise<string> => {
                return res.productName;
              }}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PricerowTable;
