import gql from 'graphql-tag';
import { CampaignInterface, CampaignPrice } from '../interfaces';
import { CampaignInput } from '../types/campaign-input';
import { CampaignPriceInput } from '../types/campaign-price-input';
import { EmailContext, parseEmailContext } from '../types/email-context';
import Client from './apollo-client';

const campaignPriceQuery = `campaignPrice {
  id
  totalPrice
  vat
  discount
  solid
  listPrice
  mediaDiscount
  discountPercent
  discountAmount
  priceRows {
    quantity
    customName
    productId
    batch
    productName
  }
}`;

class CampaignClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_CAMPAIGN_SERVICE_URL || '';
    this.client = new Client(url);
  }

  listCampaign = async (): Promise<Partial<CampaignInterface[]>> => {
    try {
      const LIST_CAMPAIGN = gql`
        query listCampaigns {
          listCampaigns {
            id
            campaignName
            description
            contactPerson
            userId
            isUpdatable
            customer {
              id
              customerName
              businessid
              address
              salesman
              contactEmail
              contactPhone
              invoiceDiscount
              companyId
              visit_address
              visit_zipcode
              visit_city
              visit_country
              contacts {
                id
                firstname
                lastname
                phone
                email
                department
              }
              invoiceAddresses {
                id
                invoice_address
                invoice_zipcode
                invoice_city
                invoice_country
              }
            }
            ${campaignPriceQuery}
            campaignDetails {
              id
              label
              key
              value
              settingId
            }
            customerId
            additionalInfo
            customerEmail
            materialApproverEmail
            materialSupplierEmail
            materialApproverPhone
            materialSupplierPhone
            confirmed
            reference
            status
            jobNumber
            advancePayment
            attachments {
              filename
              path
            }
          }
        }
      `;
      const result = await this.client.query(LIST_CAMPAIGN);

      return result.data.listCampaigns as Partial<CampaignInterface[]>;
    } catch (error) {
      throw new Error('List campaigns failes ' + error.messssage);
    }
  };

  listFloatingCampaigns = async (): Promise<Partial<CampaignInterface[]>> => {
    try {
      const LIST_CAMPAIGN = gql`
        query listFloatingCampaigns {
          listFloatingCampaigns {
            floatingOrderStatus {
              filled
              ordered
              ready
            }
            user {
              name
            }
            customer {
              id
              customerName
              businessid
              address
              salesman
              contactEmail
              contactPhone
              invoiceDiscount
              companyId
              visit_address
              visit_zipcode
              visit_city
              visit_country
              contacts {
                id
                firstname
                lastname
                phone
                email
                department
              }
              invoiceAddresses {
                id
                invoice_address
                invoice_zipcode
                invoice_city
                invoice_country
              }
            }
            ${campaignPriceQuery}
            campaignDetails {
              id
              label
              key
              value
              settingId
            }
            id
            additionalInfo
            campaignName
            description
            contactPerson
            userId
            customerId
            customerEmail
            materialApproverEmail
            materialSupplierEmail
            materialApproverPhone
            materialSupplierPhone
            confirmed
            reference
            status
            jobNumber
            invoiceInstruction
            freeText
            floating
            floatingAmount
            floatingQuantity
            floatingBatchName
            floatingVariations
            campaignNumber
            advancePayment
            reservations {
              weeks {
                batch
                id
                week
                year
                quantity
                productVariationId
                productId
              }
              months {
                batch
                id
                month
                year
                quantity
                productVariationId
                productId
              }
              days {
                batch
                id
                date
                quantity
                productVariationId
                productId
              }
            }
          }
        }
      `;

      const result = await this.client.query(LIST_CAMPAIGN);
      return result.data.listFloatingCampaigns as Partial<CampaignInterface[]>;
    } catch (error) {
      throw new Error('List campaigns failes ' + error.messssage);
    }
  };

  listActiveCampaignsByIds = async (ids: string[]): Promise<Partial<CampaignInterface[]>> => {
    try {
      const LIST_CAMPAIGN = gql`
        query listCampaignsByIds($ids: [String!]!) {
          listCampaignsByIds(ids: $ids) {
            id
            campaignName
            description
            contactPerson
            userId
            campaignNumber
            isUpdatable
            additionalInfo
            offerText
            # customer {
            #   id
            #   customerName
            #   businessid
            #   address
            #   salesman
            #   contactEmail
            #   contactPhone
            #   invoiceDiscount
            #   companyId
            #   invoiceDiscount
            #   visit_address
            #   visit_zipcode
            #   visit_city
            #   visit_country
            #   contacts {
            #     id
            #     firstname
            #     lastname
            #     phone
            #     email
            #     department
            #   }
            #   invoiceAddresses {
            #     id
            #     invoice_address
            #     invoice_zipcode
            #     invoice_city
            #     invoice_country
            #   }
            # }
            ${campaignPriceQuery}
            campaignDetails {
              id
              label
              key
              value
              settingId
            }
            user {
              name
            }
            floating
            floatingAmount
            floatingQuantity
            floatingBatchName
            floatingVariations
            customerId
            customerEmail
            customerName
            materialApproverEmail
            materialSupplierEmail
            materialApproverPhone
            materialSupplierPhone
            materialGuide
            confirmed
            reference
            invoiceInstruction
            freeText
            jobNumber
            status
            advancePayment
            attachments {
              filename
              path
            }
          }
        }
      `;
      const result = await this.client.query(LIST_CAMPAIGN, { ids });

      return result.data.listCampaignsByIds as Partial<CampaignInterface[]>;
    } catch (error) {
      throw new Error('Unable to list active campaign ids campain-client error');
    }
  };

  listActiveCampaignsByUserIdsandDate = async (
    ids: string[],
    startDate: Date,
    endDate: Date
  ): Promise<CampaignInterface[]> => {
    try {
      const LIST_CAMPAIGN = gql`
        query listActiveCampaignsByUserIds(
          $ids: [String!]!
          $startDate: DateTime!
          $endDate: DateTime!
        ) {
          listActiveCampaignsByUserIds(
            ids: $ids
            startDate: $startDate
            endDate: $endDate
          ) {
            id
            campaignName
            description
            contactPerson
            userId
            created
            campaignNumber
            offerText
            floating
            floatingAmount
            floatingQuantity
            floatingBatchName
            floatingVariations
            additionalInfo
            customer {
              id
              customerName
              businessid
              address
              salesman
              contactEmail
              contactPhone
              invoiceDiscount
              companyId
              invoiceDiscount
              visit_address
              visit_zipcode
              visit_city
              visit_country
              contacts {
                id
                firstname
                lastname
                phone
                email
                department
              }
              invoiceAddresses {
                id
                invoice_address
                invoice_zipcode
                invoice_city
                invoice_country
              }
            }
            ${campaignPriceQuery}
            campaignDetails {
              id
              label
              key
              value
              settingId
            }
            user {
              name
            }
            customerId
            customerEmail
            materialApproverEmail
            materialSupplierEmail
            materialApproverPhone
            materialSupplierPhone
            materialGuide
            confirmed
            reference
            invoiceInstruction
            freeText
            jobNumber
            status
            advancePayment
            attachments {
              filename
              path
            }
            reservations {
              weeks {
                batch
                id
                week
                year
                quantity
                productVariationId
                productId
              }
              months {
                batch
                id
                month
                year
                quantity
                productVariationId
                productId
              }
              days {
                batch
                id
                date
                quantity
                productVariationId
                productId
              }
            }
          }
        }
      `;
      const result = await this.client.query(LIST_CAMPAIGN, {
        ids,
        startDate,
        endDate,
      });

      return result.data.listActiveCampaignsByUserIds as CampaignInterface[];
    } catch (error) {
      throw new Error('List active offers failes ' + error.messssage);
    }
  };

  listOffers = async (): Promise<Partial<CampaignInterface[]>> => {
    try {
      const LIST_CAMPAIGN = gql`
        query listCampaignOffers {
          listCampaignOffers {
            id
            campaignName
            description
            contactPerson
            userId
            offerText
            additionalInfo
            user {
              name
            }
            customer {
              id
              customerName
              businessid
              address
              salesman
              contactEmail
              contactPhone
              companyId
              invoiceDiscount
              visit_address
              visit_zipcode
              visit_city
              visit_country
              contacts {
                id
                firstname
                lastname
                phone
                email
                department
              }
              invoiceAddresses {
                id
                invoice_address
                invoice_zipcode
                invoice_city
                invoice_country
              }
            }
            ${campaignPriceQuery}
            campaignDetails {
              id
              label
              key
              value
              settingId
            }
            customerId
            customerEmail
            materialApproverEmail
            materialSupplierEmail
            materialApproverPhone
            materialSupplierPhone
            confirmed
            reference
            jobNumber
            status
            attachments {
              filename
              path
            }
          }
        }
      `;
      const result = await this.client.query(LIST_CAMPAIGN);
      return result.data.listCampaignOffers as Partial<CampaignInterface[]>;
    } catch (error) {
      throw new Error('List campaigns failes ' + error.messssage);
    }
  };

  removeCampaign = async (id: string): Promise<void> => {
    try {
      const REMOVE_CAMPAIGN = gql`
        mutation removeCampaign($id: String!) {
          remove(id: $id) {
            message
            errors
          }
        }
      `;
      const result = await this.client.mutate(REMOVE_CAMPAIGN, { id });
      if (result.data.remove.errors) {
        throw new Error(result.data.remove.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  addCampaign = async ({
    campaignDetails,
    campaignName,
    description,
    customer,
    customerEmail,
    customerName,
    materialApproverEmail,
    materialSupplierEmail,
    contactPerson,
    reference,
    invoiceInstruction,
    offer,
    offerText = '',
    materialGuide = '',
    freeText,
    advancePayment = false,
    materialApproverPhone = '',
    materialSupplierPhone = '',
    attachments,
    floating = false,
    floatingAmount = 1,
    floatingQuantity = 5,
    floatingVariations = '',
    floatingBatchName = '',
    additionalInfo = '',
    offerPdf = false,
    email,
  }: CampaignInput): Promise<string> => {
    try {
      const ADD_CAMPAIGN = gql`
        mutation addCampaign(
          $campaignName: String!
          $description: String!
          $customer: ID!
          $customerEmail: String!
          $customerName: String
          $materialSupplierEmail: String!
          $materialApproverEmail: String!
          $details: [CampaignDetailInput!]
          $contactPerson: String!
          $reference: String!
          $invoiceInstruction: String
          $offer: Boolean
          $offerText: String!
          $materialGuide: String!
          $freeText: String
          $advancePayment: Boolean
          $materialApproverPhone: String
          $materialSupplierPhone: String
          $attachments: [AttachmentInputType!]
          $floating: Boolean
          $floatingAmount: Float
          $floatingQuantity: Float
          $floatingVariations: String
          $floatingBatchName: String
          $additionalInfo: String
          $offerPdf: Boolean
          $email: EmailContextInput
        ) {
          addCampaign(
            campaign: {
              campaignName: $campaignName
              contactPerson: $contactPerson
              description: $description
              customer: $customer
              customerEmail: $customerEmail
              customerName: $customerName
              materialApproverEmail: $materialApproverEmail
              materialSupplierEmail: $materialSupplierEmail
              campaignDetails: $details
              reference: $reference
              invoiceInstruction: $invoiceInstruction
              offer: $offer
              offerText: $offerText
              materialGuide: $materialGuide
              freeText: $freeText
              advancePayment: $advancePayment
              materialApproverPhone: $materialApproverPhone
              materialSupplierPhone: $materialSupplierPhone
              attachments: $attachments
              floating: $floating
              floatingAmount: $floatingAmount
              floatingQuantity: $floatingQuantity
              floatingVariations: $floatingVariations
              floatingBatchName: $floatingBatchName
              additionalInfo: $additionalInfo
              offerPdf: $offerPdf
              email: $email
            }
          ) {
            id
            campaignName
            description
            reference
          }
        }
      `;
      const result = await this.client.mutate(ADD_CAMPAIGN, {
        details: campaignDetails,
        campaignName,
        description,
        customer,
        customerEmail,
        customerName,
        materialApproverEmail,
        materialSupplierEmail,
        contactPerson,
        reference,
        invoiceInstruction,
        offer,
        offerText,
        materialGuide,
        freeText,
        advancePayment,
        materialApproverPhone,
        materialSupplierPhone,
        attachments,
        floating,
        floatingAmount,
        floatingBatchName,
        floatingQuantity,
        floatingVariations,
        additionalInfo,
        offerPdf,
        email,
      });
      return result.data.addCampaign.id;
    } catch (error) {
      console.log(error);
      throw new Error('Adding Campaign failed ' + error.message);
    }
  };

  updateCampaign = async (
    {
      campaignDetails,
      campaignName,
      description,
      customer,
      customerEmail,
      customerName,
      materialApproverEmail,
      materialSupplierEmail,
      contactPerson,
      reference,
      invoiceInstruction,
      offer,
      offerText = '',
      materialGuide = '',
      freeText,
      advancePayment = false,
      materialApproverPhone = '',
      materialSupplierPhone = '',
      attachments,
      floating = false,
      floatingAmount = 1,
      floatingQuantity = 5,
      floatingVariations = '',
      floatingBatchName = '',
      additionalInfo = '',
      offerPdf = false,
    }: CampaignInput,
    campaignId: string
  ): Promise<string> => {
    try {
      const ADD_CAMPAIGN = gql`
        mutation updateCampaign(
          $id: String!
          $campaignName: String!
          $description: String!
          $customer: ID!
          $customerEmail: String!
          $customerName: String
          $materialSupplierEmail: String!
          $materialApproverEmail: String!
          $details: [CampaignDetailInput!]
          $contactPerson: String!
          $reference: String!
          $invoiceInstruction: String
          $offer: Boolean
          $offerText: String!
          $materialGuide: String!
          $freeText: String
          $advancePayment: Boolean
          $materialApproverPhone: String
          $materialSupplierPhone: String
          $attachments: [AttachmentInputType!]
          $floating: Boolean
          $floatingAmount: Float
          $floatingQuantity: Float
          $floatingVariations: String
          $floatingBatchName: String
          $additionalInfo: String
          $offerPdf: Boolean
        ) {
          updateCampaign(
            id: $id
            campaign: {
              campaignName: $campaignName
              contactPerson: $contactPerson
              description: $description
              customer: $customer
              customerEmail: $customerEmail
              customerName: $customerName
              materialApproverEmail: $materialApproverEmail
              materialSupplierEmail: $materialSupplierEmail
              campaignDetails: $details
              reference: $reference
              invoiceInstruction: $invoiceInstruction
              offer: $offer
              offerText: $offerText
              materialGuide: $materialGuide
              freeText: $freeText
              advancePayment: $advancePayment
              materialApproverPhone: $materialApproverPhone
              materialSupplierPhone: $materialSupplierPhone
              attachments: $attachments
              floating: $floating
              floatingAmount: $floatingAmount
              floatingQuantity: $floatingQuantity
              floatingVariations: $floatingVariations
              floatingBatchName: $floatingBatchName
              additionalInfo: $additionalInfo
              offerPdf: $offerPdf
            }
          ) {
            id
            campaignName
            description
            reference
          }
        }
      `;
      const result = await this.client.mutate(ADD_CAMPAIGN, {
        details: campaignDetails,
        campaignName,
        description,
        customer,
        customerEmail,
        customerName,
        materialApproverEmail,
        materialSupplierEmail,
        contactPerson,
        reference,
        invoiceInstruction,
        offer,
        offerText,
        materialGuide,
        freeText,
        advancePayment,
        materialApproverPhone,
        materialSupplierPhone,
        attachments,
        id: campaignId,
        floating,
        floatingAmount,
        floatingBatchName,
        floatingQuantity,
        floatingVariations,
        additionalInfo,
        offerPdf,
      });
      return result.data.updateCampaign.id;
    } catch (error) {
      console.log(error);
      throw new Error('Adding Campaign failed ' + error.message);
    }
  };

  getCampaign = async (id: string): Promise<CampaignInterface> => {
    try {
      const GET_CAMPAIGN = gql`
        query campaign($id: String!) {
          campaign(id: $id) {
            id
            campaignName
            description
            user {
              name
            }
            campaignNumber
            jobNumber
            customer {
              customerName
              businessid
              address
              salesman
              contactEmail
              contactPhone
              invoiceDiscount
              companyId
            }
          }
        }
      `;
      const result = await this.client.query(GET_CAMPAIGN, {
        id,
      });

      return result.data.campaign;
    } catch (error) {
      console.log(error);
      throw new Error('Cannot get campaign');
    }
  };

  confirmCampaign = async (id: string): Promise<string> => {
    try {
      const CONFIRM_CAMPAIGN = gql`
        mutation campaign($id: String!) {
          confirmCampaign(id: $id) {
            id
            campaignName
            confirmed
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_CAMPAIGN, {
        id,
      });
      console.log(data);
      if (errors || !data.confirmCampaign.confirmed) throw Error('Confirmation failed');
      return data.confirmCampaign.id;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  confirmCampaignOffer = async (id: string): Promise<string> => {
    try {
      const CONFIRM_CAMPAIGN = gql`
        mutation campaign($id: String!) {
          confirmCampaignOffer(id: $id) {
            id
            campaignName
            confirmed
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_CAMPAIGN, {
        id,
      });
      if (errors) throw Error('Confirmation failed');
      return data.confirmCampaignOffer.id;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  addCampaignPrice = async (price: CampaignPriceInput): Promise<CampaignPrice> => {
    try {
      const FIND_PRODUCT_SETTING = gql`
        mutation addCampaignPrice($price: CampaignPriceInput!) {
          addCampaignPrice(price: $price) {
            totalPrice
            vat
            listPrice
            solid
            discount
            id
            discountAmount
            discountPercent
          }
        }
      `;

      const result = await this.client.mutate(FIND_PRODUCT_SETTING, {
        price,
      });
      if (result.errors) throw new Error(result.errors[0]);
      return result.data as CampaignPrice;
    } catch (error) {
      throw new Error(error);
    }
  };

  updateCampaignStatus = async (status: number, id: string): Promise<number> => {
    try {
      const UPDATE_STATUS = gql`
        mutation updateCampaignStatus($status: Float!, $id: String!) {
          updateCampaignStatus(id: $id, status: $status) {
            status
          }
        }
      `;
      const result = await this.client.mutate(UPDATE_STATUS, {
        status,
        id,
      });

      return result.data.status;
    } catch (error) {
      throw new Error(error);
    }
  };

  checkFloatingCampaignStatus = async (id: string): Promise<number> => {
    try {
      const UPDATE_STATUS = gql`
        mutation checkFloatingCampaign($id: String!) {
          checkFloatingCampaign(id: $id) {
            id
          }
        }
      `;
      const result = await this.client.mutate(UPDATE_STATUS, {
        id,
      });
      return result.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  getCampaigLogsByActionsId = async (actionId: string): Promise<any> => {
    try {
      const GET_CAMPAIGN_LOGS = gql`
        query list($actionId: String!) {
          listLogsByActionID(actionId: $actionId) {
            id
            created
            campaignId
            userId
            action
          }
        }
      `;
      const result = await this.client.query(GET_CAMPAIGN_LOGS, {
        actionId,
      });
      return result.data.listLogsByActionID;
    } catch (error) {
      console.log(error);
      throw new Error('Cannot get campaign');
    }
  };

  getCampaigLogsByCampaignId = async (campaignId: string): Promise<any> => {
    try {
      const GET_CAMPAIGN_LOGS = gql`
        query listLogsByCampaignID($campaignId: String!) {
          listLogsByCampaignID(campaignId: $campaignId) {
            id
            created
            campaignId
            userId
            action
          }
        }
      `;
      const result = await this.client.query(GET_CAMPAIGN_LOGS, {
        campaignId,
      });
      return result.data.listLogsByCampaignID;
    } catch (error) {
      console.log(error);
      throw new Error('Cannot get campaign');
    }
  };

  generateEmailContext = async (
    campaign: CampaignInput,
    price: CampaignPriceInput
  ): Promise<EmailContext> => {
    const GET_EMAIL_CONTEXT = gql`
      mutation getEmailContext($campaign: CampaignInput!, $price: CampaignPriceInput!) {
        generateEmailContext(price: $price, campaign: $campaign) {
          orderNumber
          orderTime
          ordererName
          ordererBusinessID
          ordererBillingAddress
          ordererContactName
          ordererContactEmail
          productName
          productVAT
          productTotal
          discount
          discountPercent
          reference
          offerText
          materialGuide
          freeText
          costText
          tapeText
          productPriceWithoutTaxes
          showMoreInstructions
          quantityArray
          isTape
          campaignName
          mediaDiscount
          priceRows {
            rowPrice
            batch
            customName
            discountPercentage
            basePrice
            unitPrice
            quantity
            productName
            productId
          }
        }
      }
    `;

    const result = await this.client.mutate(GET_EMAIL_CONTEXT, {
      campaign,
      price,
    });

    const emailContext = parseEmailContext(result.data.generateEmailContext);

    return emailContext;
  };

  generateEmailOfferHtml = async (context: EmailContext): Promise<string> => {
    const GET_EMAIL_HTML = gql`
      query genOfferHtml($context: EmailContextInput!) {
        generateOfferEmailHtml(context: $context) {
          html
        }
      }
    `;
    const result = await this.client.query(GET_EMAIL_HTML, {
      context,
    });

    return result.data.generateOfferEmailHtml.html;
  };
}
export default new CampaignClient();
