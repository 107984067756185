/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, makeStyles, TextareaAutosize, Typography } from '@material-ui/core';
import { FormikErrors } from 'formik';
import React, { ChangeEvent } from 'react';

interface Props {
  name: string;
  title: string;
  placeholder: string;
  minHeight?: string;
  formik: {
    handleChange: (
      eventOrPath: string | ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | ChangeEvent<any>) => void);
    errors: FormikErrors<any>;
    values: any;
  };
}

const useStyles = makeStyles((theme) => ({
  rightItem: {
    width: '400px',
  },
  container: {
    paddingLeft: '24px',
    width: '800px',
    marginTop: '60px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  textArea: (props: { minHeight: string }) => ({
    minHeight: props.minHeight,
    minWidth: '300px',
    width: '100%',
  }),
  inputContainer: {
    justifyContent: 'space-between',
  },
}));

const SummaryFormTextArea: React.FC<Props> = ({
  name,
  title,
  formik,
  minHeight = '100px',
}: Props) => {
  const classes = useStyles({ minHeight });
  return (
    <Grid container className={classes.inputContainer} item style={{ marginBottom: '60px' }}>
      <Grid item>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid className={classes.rightItem} item>
        <Grid container>
          <TextareaAutosize
            name={name}
            onChange={formik.handleChange}
            placeholder=""
            className={classes.textArea}
            value={formik.values[name]}
          ></TextareaAutosize>
          <Typography className={classes.error}>
            {formik.errors[name] ? formik.errors[name] : null}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryFormTextArea;
