import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import {
  AddOrderState,
  CustomerCampaign,
  MultipressCalculatedPrice,
  OrderDetails,
  OrderSummary,
} from '../../interfaces';
import { CampaignInput } from '../../types/campaign-input';
import { parseCampaignPriceInput } from '../../types/campaign-price-input';
import { OrderBatch } from '../../types/order-batch';
import { checkSession, setSession } from '../../utils/session-utils';
import { toastOptions } from '../../utils/toast-options';
import CampaignClient from '../campaign-client';
import addCampaignPrice from './add-campaign-price';
import addMonthReservations from './add-month-reservations';
import addReservations from './add-reservations';
import addWeekReservations from './add-week-reservations';
import downloadOrderPdf from './download-order-pdf';

const addCampaign = async (
  orderDetails: OrderDetails,
  campaign: CustomerCampaign,
  orderSummary: OrderSummary,
  price: MultipressCalculatedPrice,
  setSending: Function,
  batches: OrderBatch[],
  addOrderState: AddOrderState,
  offerId?: string,
  pdf = false
): Promise<void> => {
  const { floatingOrder } = addOrderState;
  const campaignData: CampaignInput = {
    ...campaign,
    campaignName: campaign.name,
    customer: campaign.customerId,
    description: '',
    offer: false,
    campaignDetails: orderDetails.campaignDetails,
    ...orderSummary,
    floating: floatingOrder ? floatingOrder.floating : false,
    floatingAmount: floatingOrder ? floatingOrder.floatingAmount : 1,
    floatingQuantity: floatingOrder ? floatingOrder.quantity : 5,
    floatingVariations: floatingOrder ? floatingOrder.variations.join(',') : '',
    floatingBatchName: floatingOrder ? floatingOrder.batchName : '',
    offerPdf: pdf,
  };
  setSending(true);

  let campaignId = null;

  try {
    campaignId = await CampaignClient.addCampaign(campaignData as CampaignInput);
    toast.success('Kampanja lisätty', toastOptions);
  } catch (error) {
    toast.error('Kampanjan lisäys epäonnistui', toastOptions);
    setSending(false);
    return;
  }

  //varaukset
  try {
    if (addOrderState.limitType === 'month') {
      await addMonthReservations({
        campaign: campaignId,
        rough: false,
        product: addOrderState.productId,
        batches,
      });
    }
    if (addOrderState.limitType === 'week') {
      await addWeekReservations({
        campaign: campaignId,
        rough: false,
        product: addOrderState.productId,
        batches,
      });
    } else {
      await addReservations({
        product: addOrderState.productId,
        batches,
        campaign: campaignId,
      });
    }
    toast.success('Tilaukset lisätty', toastOptions);
  } catch (error) {
    toast.error('Tilauksien lisäys epäonnistui. Tarkista ajanjaksot.', toastOptions);
    await CampaignClient.removeCampaign(campaignId);
    setSending(false);
    return;
  }

  //campaign price
  try {
    await addCampaignPrice(
      parseCampaignPriceInput({
        ...price,
        campaign: campaignId,
      })
    );
    toast.success('Hinnat lisätty', toastOptions);
  } catch (error) {
    toast.error('Hintojen lisäys epäonnistui', toastOptions);
    await CampaignClient.removeCampaign(campaignId);
    setSending(false);
    return;
  }

  // tarjouksen poisto
  try {
    if (offerId) await CampaignClient.removeCampaign(offerId);
  } catch (error) {
    toast.error('Jotain meni vikaan tarjousta siivotessa', toastOptions);
    await CampaignClient.removeCampaign(campaignId);
    setSending(false);
    return;
  }
  //floating check
  try {
    if (floatingOrder?.floating) await CampaignClient.checkFloatingCampaignStatus(campaignId);
  } catch {
    //pass
  }

  if (pdf) {
    if (checkSession()) setSession();
    else navigate('/');
    await downloadOrderPdf(campaignId);
  }
  setSending(false);
  if (floatingOrder && floatingOrder.floating) {
    navigate('/floatingOrders');
    return;
  }
  navigate('/orders');
};

export default addCampaign;
