import {
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import CampaignHeaderColumn from '../components/campaign-list/campaign-header-column';
import Campaign from '../components/campaign-list/floating-campaign';
import LoadingSpinner from '../components/loader';
import {
  CampaignInterface,
  ProductVariationInterface,
  ProviderInterface,
  ReservationDaysMonths,
  ReservationInterface,
} from '../interfaces';
import campaignClient from '../lib/campaign-client';
import productClient from '../lib/product-client';
import { sortMonthReservations } from '../utils/reservation-utils';

const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    margin: '0 auto',
  },

  gridContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
  },
  mainContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
    paddingTop: '70px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '70px',
  },
  title: {
    fontSize: '34px',
    letterSpacing: '0.24px',
    lineHeight: '40px',
    fontWeight: 400,
    color: '#000000DE',
    'margin-bottom': '25px',
  },
  table: {
    minWidth: 1200,
  },
  headerCell: {},
  labelActive: { color: '#FFFFFF !important', opacity: '1 !important' },
  icon: {
    display: 'none ',
  },
  headerLabel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    fontSize: '16px',
    '&:hover': {
      color: '#FFFFFF',
      opacity: 1,
    },
    '&:focus': {
      color: '#FFFFFF',
      opacity: 1,
    },
  },
  headerRow: {
    backgroundColor: '#000000',
  },
  labelContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortLabel: {
    width: '100%',
  },
  HeaderCell: {
    minWidth: '100px',
  },
  digiTapeFilter: {
    minWidth: '120px',
    marginLeft: '30px',
  },
}));

export type Order = 'desc' | 'asc' | undefined;

export type OrderBy =
  | 'customerName'
  | 'salesman'
  | 'jobNumber'
  | 'campaignName'
  | 'date'
  | 'status'
  | 'material'
  | 'created'
  | 'username'
  | 'reservationCount'
  | 'reservationDate'
  | 'campaignNumber'
  | undefined;

type FloatingListContainerProps = {
  restrictedProducts?: string[];
  admin?: boolean;
};

const OfferListContainer: React.FC<FloatingListContainerProps> = ({
  restrictedProducts,
  admin = false,
}: FloatingListContainerProps) => {
  const [campaigns, setCampaigns] = useState<(CampaignInterface | undefined)[]>();
  const [digiTapeFilter, setDigiTapeFilter] = useState('all');
  const [filteredCampaigns, setFiltered] = useState<(CampaignInterface | undefined)[]>();
  const [order, setOrder] = React.useState<Order>('desc');
  const [fetching, setFetching] = useState(false);
  const [orderBy, setOrderBy] = React.useState<OrderBy>('campaignNumber');
  const [searchInput, setSearchInput] = useState('');
  const [variations, setVariations] = useState<ProductVariationInterface[]>();
  const [provider, setProvider] = useState<ProviderInterface>();
  const classes = useStyles();

  const handleSort = (property: OrderBy, orderStyle: Order): void => {
    if (!campaigns) {
      return;
    }
    const sortCampaigns = campaigns.sort((a: any, b: any) => {
      if (a && b) {
        if (property) {
          if (property === 'salesman') {
            return ('' + b.user.name).localeCompare('' + a.user.name);
          }
          if (property === 'customerName') {
            return ('' + b.customer[property]).localeCompare('' + a.customer[property]);
          }
          if (property === 'date') {
            let aDate = a.reservations.length > 0 ? a.reservations[0].date : 0;
            let bDate = b.reservations.length > 0 ? b.reservations[0].date : 0;

            const aSorted =
              a.reservations.length && a.reservations[0].month > 0
                ? sortMonthReservations(a.reservations)
                : a.reservations;

            const bSorted =
              b.reservations.length && b.reservations[0].month > 0
                ? sortMonthReservations(b.reservations)
                : b.reservations;

            const aMonthYear = {
              month: aSorted[0].month,
              year: aSorted[0].year,
            };

            const bMonthYear = {
              month: bSorted[0].month,
              year: bSorted[0].year,
            };

            if (aMonthYear.month) aDate = new Date(`${aMonthYear.year}.${aMonthYear.month}.1`);
            if (bMonthYear.month) bDate = new Date(`${bMonthYear.year}.${bMonthYear.month}.1`);

            return new Date(aDate).getTime() - new Date(bDate).getTime();
          }

          if (property === 'campaignNumber') {
            let aValue = a[property];
            let bValue = b[property];
            if (!aValue) aValue = 0;
            else aValue = +aValue;
            if (!bValue) bValue = 0;
            else bValue = +bValue;
            return +a[property] - +b[property];
          }

          if (property === 'status') {
            let aValue = +a[property];
            let bValue = +b[property];
            if (!aValue) aValue = 0;
            else aValue = +aValue;
            if (!bValue) bValue = 0;
            else bValue = +bValue;
            return +a[property] - +b[property];
          }
          return ('' + b[property]).localeCompare('' + a[property]);
        }
      }
      return 0;
    });
    const isDesc = orderStyle === 'desc';
    if (isDesc) sortCampaigns.reverse();
    setCampaigns(sortCampaigns);
  };

  const handleRequestSort = async (property: OrderBy): Promise<void> => {
    const isDesc = order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    setOrder(newOrder);
    handleSort(property, newOrder);
    setOrderBy(property);
  };

  useEffect(() => {
    if (orderBy) handleSort(orderBy, order);
    setFiltered(campaigns);
  }, [campaigns]);

  const getCampaigns = async (): Promise<void> => {
    try {
      setFetching(true);
      setCampaigns([]);

      const floatingCampaigns = await campaignClient.listFloatingCampaigns();

      if (floatingCampaigns.length === 0) setFetching(false);

      const listPromises = floatingCampaigns
        .filter((campaign) => {
          if (campaign && campaign.id) {
            const result = campaign.reservations as unknown as ReservationDaysMonths;

            if (
              restrictedProducts &&
              result.months.some((reserv) => restrictedProducts.includes(reserv.productId))
            )
              return false;

            if (
              restrictedProducts &&
              result.days.some((reserv) => restrictedProducts.includes(reserv.productId))
            )
              return false;

            if (
              restrictedProducts &&
              result.weeks.some((reserv) => restrictedProducts.includes(reserv.productId))
            )
              return false;

            return true;
          }
        })
        .map(async (campaign) => {
          if (campaign && campaign.id) {
            try {
              const result = campaign.reservations as unknown as ReservationDaysMonths;

              let reservationArray: ReservationInterface[] = [];

              if (result.days.length > 0) {
                reservationArray = result.days;
              }
              if (result.weeks.length > 0) {
                reservationArray = result.weeks;
              }
              if (result.months.length > 0) {
                campaign.isMonth = true;
                reservationArray = result.months;
              }

              const reservations2: ReservationInterface[] = [];

              reservationArray.forEach((r) => {
                if (r) reservations2.push(r);
              });

              reservations2.sort((a, b) => {
                if (a.date && b.date)
                  return new Date(a.date).getTime() - new Date(b.date).getTime();

                return 0;
              });
              if (result) campaign.reservations = reservations2;
              return campaign;
            } catch (error) {
              //pass
              setFetching(false);
            }
          }
        });
      const list = await Promise.all(listPromises);
      setCampaigns(list);

      // setCampaigns(campaignArray);
      setFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getVariations = async (): Promise<void> => {
      try {
        const provider = await productClient.getProvider(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_PROVIDER_UUID || ''
        );
        const allVariations: ProductVariationInterface[] = [];
        provider.products?.forEach((product) => {
          product.variations?.forEach((variation) => {
            allVariations.push(variation);
          });
        });
        setVariations(allVariations);
      } catch (error) {
        throw new Error();
      }
    };
    const getProvider = async (): Promise<void> => {
      const providerRes = await productClient.getProvider(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PROVIDER_UUID || ''
      );
      setProvider(providerRes);
    };
    getVariations();
    getCampaigns();
    getProvider();
  }, [restrictedProducts]);

  const createSortHandler = (property: OrderBy) => (): void => {
    handleRequestSort(property);
  };

  const inputOnchange = (value: any): void => {
    setSearchInput(value.currentTarget.value.toLocaleLowerCase());
  };

  const onChangeDigiTapeFilter = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    setDigiTapeFilter(e.target.value + '');
  };

  useEffect(() => {
    if (campaigns) {
      const filterCampaigns = [...campaigns];

      const filtered = filterCampaigns.filter((campaign) => {
        const material = campaign?.campaignDetails.find((detail) => detail.label === 'Aineisto');

        if (
          campaign?.customer.customerName.toLocaleLowerCase().includes(searchInput) ||
          campaign?.campaignName.toLocaleLowerCase().includes(searchInput) ||
          campaign?.customer.salesman.toLocaleLowerCase().includes(searchInput) ||
          material?.value.toLocaleLowerCase().includes(searchInput) ||
          campaign?.jobNumber?.toString().toLocaleLowerCase().includes(searchInput)
        ) {
          return campaign;
        }
      });

      setFiltered(filtered);
    }
  }, [searchInput]);

  const listCampaigns = filteredCampaigns?.filter((campaign) => {
    if (!campaign || !campaign.reservations) return false;
    if (digiTapeFilter === 'all') return true;
    if (digiTapeFilter === 'tape' && campaign.reservations.some((r) => r.month)) return true;
    if (digiTapeFilter === 'digi' && campaign.reservations.some((r) => r.date)) return true;
    if (digiTapeFilter === 'keskustori' && campaign.reservations.some((r) => r.week)) return true;
    return false;
  });

  return (
    <Grid container spacing={2} className={classes.mainContainer}>
      <Grid item container className={classes.grid}>
        <Typography className={classes.title}>Kelluvat tilaukset</Typography>
        <Grid container direction="row" style={{ marginBottom: '30px' }}>
          <OutlinedInput
            id="input-with-icon-adornment"
            placeholder="Etsi hakusanalla"
            onChange={inputOnchange}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon></SearchIcon>
              </InputAdornment>
            }
          />
          <Grid>
            <FormControl className={classes.digiTapeFilter}>
              <Select value={digiTapeFilter} onChange={onChangeDigiTapeFilter}>
                <MenuItem value="all">Kaikki</MenuItem>
                <MenuItem value="digi">Digipinnat</MenuItem>
                {!restrictedProducts && <MenuItem value="tape">Ratikan teippaukset</MenuItem>}{' '}
                <MenuItem value="keskustori">Keskustori diginäytöt</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell></TableCell>
                  <CampaignHeaderColumn
                    label="Asiakas"
                    sortImage="customerName"
                    sortHandler={createSortHandler('customerName')}
                    order={order}
                    orderBy={orderBy}
                  />
                  <CampaignHeaderColumn
                    label="Kampanja"
                    sortImage="campaignName"
                    sortHandler={createSortHandler('campaignName')}
                    order={order}
                    orderBy={orderBy}
                  />
                  <CampaignHeaderColumn
                    label="Varaustilanne"
                    sortImage="date"
                    sortHandler={createSortHandler('date')}
                    order={order}
                    orderBy={orderBy}
                  />
                  <CampaignHeaderColumn
                    label="Myyjä"
                    sortImage="salesman"
                    sortHandler={createSortHandler('salesman')}
                    order={order}
                    orderBy={orderBy}
                  />

                  <>
                    <CampaignHeaderColumn
                      label="Tilausnumero"
                      sortImage="campaignNumber"
                      sortHandler={createSortHandler('campaignNumber')}
                      order={order}
                      orderBy={orderBy}
                    />
                    <CampaignHeaderColumn
                      label="Tila"
                      sortImage="status"
                      sortHandler={createSortHandler('status')}
                      order={order}
                      orderBy={orderBy}
                    />
                    <CampaignHeaderColumn
                      label="Aineisto"
                      sortImage="material"
                      sortHandler={createSortHandler('material')}
                      order={order}
                      orderBy={orderBy}
                    />
                    <CampaignHeaderColumn label="Toiminnot" />
                  </>
                </TableRow>
              </TableHead>
              <TableBody>
                {listCampaigns &&
                  listCampaigns.map((campaign: CampaignInterface | undefined) => (
                    <Campaign
                      key={campaign?.id}
                      refresh={getCampaigns}
                      singleCampaign={campaign}
                      type={'floating'}
                      variations={variations ? variations : []}
                      provider={provider}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <LoadingSpinner showSpinner={fetching}></LoadingSpinner>
    </Grid>
  );
};

export default OfferListContainer;
