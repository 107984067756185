import {
  MultipressCalculatedPrice,
  ProductInterface,
  ProductSettingInterface,
  ProductVariationInterface,
  ProviderInterface,
} from '../interfaces';

import gql from 'graphql-tag';
import Client from './apollo-client';

class ProductClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_CAMPAIGN_SERVICE_URL || '';
    this.client = new Client(url);
  }

  getProvider = async (id: string): Promise<ProviderInterface> => {
    try {
      const FIND_PROVIDER = gql`
        query provider($id: String!) {
          provider(id: $id) {
            id
            created
            name
            products {
              name
              id
              defaultProduct
              minOrderQuantity
              limitType
              maxVariations
              noFloating
              productSettings {
                id
                type
                label
                required
                defaultValue
                otherOption {
                  type
                  min
                  max
                  step
                  adornment
                  errorMessage
                }
                options {
                  label
                  value
                  default
                }
              }
              variations {
                name
                id
                order
              }
            }
          }
        }
      `;
      const result = await this.client.query(FIND_PROVIDER, { id });
      return result.data.provider as ProviderInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  getProduct = async (id: string): Promise<ProductInterface> => {
    try {
      const FIND_PRODUCT = gql`
        query findProduct($id: String!) {
          product(id: $id) {
            id
            name
            minOrderQuantity
            limitType
            maxVariations
            noFloating
            provider {
              id
              name
            }
            productSettings {
              id
              type
              label
              required
              defaultValue
              otherOption {
                type
                min
                max
                step
                adornment
                errorMessage
              }
              options {
                label
                value
                default
              }
            }
            variations {
              name
              id
              order
            }
          }
        }
      `;
      const result = await this.client.query(FIND_PRODUCT, { id });

      return result.data.product as ProductInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  getProductVariations = async (id: string): Promise<Partial<ProductVariationInterface>> => {
    try {
      const FIND_PRODUCT_VARIATION = gql`
        query findProductVariation($id: String!) {
          productVariation(id: $id) {
            id
            name
            order
            product {
              id
              name
            }
            limits {
              amount
              id
            }
          }
        }
      `;
      const result = await this.client.query(FIND_PRODUCT_VARIATION, { id });
      return result.data.productVariation as Partial<ProductVariationInterface>;
    } catch (error) {
      throw new Error(error);
    }
  };

  getProductSettings = async (id: string): Promise<ProductSettingInterface> => {
    try {
      const FIND_PRODUCT_SETTING = gql`
        query findProductSetting($id: String!) {
          productSetting(id: $id) {
            id
            type
            label
            required
            options {
              label
              value
            }
            defaultValue
            product {
              id
              name
            }
          }
        }
      `;
      const result = await this.client.query(FIND_PRODUCT_SETTING, { id });

      return result.data.productSetting as ProductSettingInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  calculateMultipressPrice = async (order: {
    productId: string;
    variations: number;
    units: number;
    quantity: number;
    discountValue: number;
    isPercentage: boolean;
    customerDiscount: number;
    vat: number;
    rowName: string;
    mediaDiscount?: boolean;
  }): Promise<MultipressCalculatedPrice> => {
    try {
      const FIND_PRODUCT_SETTING = gql`
        mutation calculatePrice($order: MultipressCalculatePriceInput!) {
          multipressCalculatePrice(order: $order) {
            totalPrice
            vat
            discount
            listPrice
            mediaDiscount
            solid
            priceRows {
              productId
              productName
              quantity
              unitPrice
              basePrice
              discountPercentage
            }
          }
        }
      `;
      const result = await this.client.mutate(FIND_PRODUCT_SETTING, {
        order: { ...order, mediaDiscount: !!order.mediaDiscount },
      });

      const res = result.data.multipressCalculatePrice as MultipressCalculatedPrice;

      return res;
    } catch (error) {
      throw new Error(error);
    }
  };

  calculateFromSolidPrice = async (
    order: {
      productId: string;
      variations: number;
      units: number;
      quantity: number;
      discountValue: number;
      isPercentage: boolean;
      customerDiscount: number;
      vat: number;
      rowName: string;
      mediaDiscount?: boolean;
    },
    listPrice: number
  ): Promise<MultipressCalculatedPrice> => {
    try {
      const FIND_PRODUCT_SETTING = gql`
        mutation calculatePrice($order: MultipressCalculatePriceInput!, $listPrice: Float!) {
          calculateFromSolidPrice(order: $order, listPrice: $listPrice) {
            totalPrice
            vat
            discount
            listPrice
            solid
            mediaDiscount
            priceRows {
              productId
              productName
              quantity
              unitPrice
              basePrice
              discountPercentage
            }
          }
        }
      `;
      const result = await this.client.mutate(FIND_PRODUCT_SETTING, {
        order: { ...order, mediaDiscount: !!order.mediaDiscount },
        listPrice: listPrice,
      });
      return result.data.calculateFromSolidPrice as MultipressCalculatedPrice;
    } catch (error) {
      throw new Error(error);
    }
  };
}
export default new ProductClient();
